<!-- @format -->

<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8" class="woc">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <img :src="require('@/assets/logo.svg')" alt="" style="width: 120px">
                  <!-- <h3 class="text-primary invoice-logo">
                    WOW
                  </h3> -->
                </div>
                <p class="card-text mb-25">Policy Number: {{ invoiceData.order_number }}</p>
                <p class="card-text mb-25">Product name: {{ invoiceData.product.title }}</p>
                <p class="card-text mb-0">Insured: {{ invoiceData.protect_user }}</p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Invoice
                  <span class="invoice-number">#{{ invoiceData.id }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Date of Creatuon:</p>
                  <p class="invoice-date">
                    {{ invoiceData.created_at }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Policy Status:</p>
                  <p class="invoice-date">
                    {{ invoiceData.stu_name }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <h6 class="mb-2">Product Details:</h6>
                <h6 class="mb-25">Company: {{ invoiceData.brand_name }}</h6>
                <h6 class="mb-25">Product: {{ invoiceData.product.title }}</h6>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                <div>
                  <h6 class="mb-2">Policy Details:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">Policy Number:</td>
                        <td>
                          <span class="font-weight-bold">{{ invoiceData.number }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">Type:</td>
                        <td>{{ invoiceData.count_cate }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="invoiceDescription"
            :fields="[
              {
                key: 'search_id',
                label: 'Agent Id',
              },
              {
                key: 'create_name',
                label: 'Agent name',
              },
              {
                key: 'create_level',
                label: 'Agent Level',
              },
              {
                key: 'share_rate',
                label: 'Split Percent',
              },
            ]"
          />

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Salesperson:</span>
                  <span class="ml-75">
                    <tr>
                      <td class="pr-1">Create_name:</td>
                      <td>{{ invoiceData.create_name }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">Create_level:</td>
                      <td>{{ invoiceData.create_level }}</td>
                    </tr>
                  </span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-center" order="1" order-md="2">
                <div class="invoice-total-wrapper" style="max-width: inherit">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Face Amount:</p>
                    <p class="invoice-total-amount">
                      {{ '$'+String(invoiceData.money).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Date:</p>
                    <p class="invoice-total-amount">
                      {{ invoiceData.deal_time }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">State:</p>
                    <p class="invoice-total-amount">
                      {{ invoiceData.sign_city }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Remarks: </span>
            <span>{{ invoiceData.desc }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: DOwnload -->
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="downLoad"> Download </b-button>
        </b-card>
      </b-col>
    </b-row>

    <!-- <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import { ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle } from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import invoiceStoreModule from '../invoiceStoreModule'
import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
    InvoiceSidebarAddPayment,
    InvoiceSidebarSendInvoice,
  },
  setup() {
    const invoiceData = ref({
      id: '',
      number: '',
      issuedDate: '13 Dec 2019',
      client: {
        address: '7777 Mendez Plains',
        company: 'Hall-Robbins PLC',
        companyEmail: 'don85@johnson.com',
        country: 'USA',
        contact: '(616) 865-4180',
        name: 'Jordan Stevenson',
      },
      product: {},
      service: 'Software Development',
      total: 3428,
      avatar: '',
      invoiceStatus: 'Paid',
      balance: '$724',
      dueDate: '23 Apr 2019',
    })
    const paymentDetails = ref({
      totalDue: '$12,110.55',
      bankName: 'American Bank',
      country: 'United States',
      iban: 'ETD95476213874685',
      swiftCode: 'BR91905',
    })

    // Invoice Description
    const invoiceDescription = ref([
      {
        taskTitle: 'Native App Development',
        taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
        rate: '$60.00',
        hours: '30',
        total: '$1,800.00',
      },
      {
        taskTitle: 'UI Kit Design',
        taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
        rate: '$60.00',
        hours: '20',
        total: '$1200.00',
      },
    ])

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })

    const formData = computed(() => store.state['user-orders'].orderDetail)
    // console.log(formData.value)
    // id
    invoiceData.value.id = formData.value.id
    invoiceData.value.number = formData.value.number
    invoiceData.value.product = formData.value.product
    invoiceData.value.protect_user = formData.value.protect_user
    invoiceData.value.created_at = formData.value.created_at
    invoiceData.value.apply_time = formData.value.apply_time
    invoiceData.value.money = formData.value.money
    invoiceData.value.count_cate = formData.value.count_cate
    invoiceData.value.order_number = formData.value.order_number
    invoiceData.value = Object.assign(invoiceData.value, formData.value)

    //
    invoiceData.value.order_tp = formData.value.order_tp

    invoiceDescription.value = formData.value.share_rate

    const printInvoice = () => {
      window.print()
    }

    const downLoad = () => {
      document.body.style.cursor = 'wait'
      setTimeout(() => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF('l')

        html2canvas(document.querySelector('.woc '), {
          height: 646,
          width: 1196,
          // height: document.querySelector('.woc').scrollHeight,
          backgroundColor: '#fff',
          scale: 2,
          removeContainer: true,
          allowTaint: true,
          // 背景大小
          bcckgroundSize: 'cover',
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/jpeg')
          doc.addImage(imgData, 'JPEG', 0, 0, 290, 200, '')
          doc.save('invoice.pdf')
          setTimeout(() => {
            document.body.style.cursor = 'default'
          }, 1000)
        })
      }, 1000)
    }
    return {
      downLoad,
      formData,
      invoiceData,
      paymentDetails,
      invoiceDescription,
      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>

<!-- @format

<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      Col: Left (Invoice Container)

      <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
        <b-card>
                   <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-75" block @click.prevent="submitAddProduct">Confirm</b-button>
                   <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="yulandis = !yulandis"> {{ yulandis ? 'Cancel Preview' : 'Preview' }} </b-button>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="downLoad"> Download </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, getCurrentInstance, computed, nextTick, onMounted, reactive, watch, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ListManagerModule from '@/views/GeneralManagement/ProductManager/List/ListManagerModule'

import { BRow, BCol, BCard, BCardBody, BButton, BForm, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axiosIns from '@/libs/axios'
import { useToast } from 'vue-toastification/composition'
import orderModule from '@/views/GeneralManagement/OrderManager/OrderList/OrderManagerList'
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import zh from 'flatpickr/dist/l10n'
import { required } from 'vee-validate/dist/rules'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    addNewItemInItemForm() {
      if (this.formData.share_rate.length < 6) {
        this.$refs.form.style.overflow = 'hidden'
        this.formData.share_rate.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      } else {
        this.$bvToast.toast('Add up to 6 Split Percent', {
          title: 'Tips',
          variant: 'warning',
          solid: true,
          autoHideDelay: 2000,
        })
      }
    },
    removeItem(index) {
      this.formData.share_rate.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      setTimeout(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            if (this.$refs.form && this.$refs.form.scrollHeight) {
              this.trSetHeight()
            }
          }, 1000)
        })
      }, 200)
    },
    forceUpdated() {
      this.$forceUpdate()
    },
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const toast = useToast()

    const formData = ref(store.state['user-order'].orderDetail)

    const timePickerConfig = {
      enableTime: true,
      dateFormat: 'Y-m-d H:i:ss',
      locale: zh.zh, // locale for this instance only
    }

    const productList = reactive([])
    const casePagae = ref(1)
    const total = ref(0)
    const hasPrevPage = computed(() => casePagae.value > 1)
    const hasNextPage = computed(() => casePagae.value < Math.ceil(total.value / 20))

    // 会员列表
    const vipList = reactive([])
    const fetchProdList = () => {
      if (!store.hasModule('product-list')) store.registerModule('product-list', ListManagerModule)
      store
        .dispatch('product-list/fetchDataList', {
          pageSize: 20,
          page: casePagae.value,
        })
        .then(response => {
          productList.push(...response.data.list)
          total.value = response.data.total
        })
    }
    // const cityList = reactive([])

    const itemFormBlankItem = {
      user_id: undefined,
      share_rate: 0,
    }

    const error = reactive({
      text: '',
      component: '',
    })

    onMounted(() => {
      nextTick(() => {
        fetchProdList()
        axiosIns.get('/user/list').then(response => {
          if (response.code === 0) {
            vipList.push(...response.data.list)
          }
        })
        axiosIns.get('/order/create-user').then(response => {
          formData.value.create_name = response.data.real_name
          formData.value.create_id = response.data.id
          formData.value.create_level = response.data.level.name
        })
        if (formData.value && formData.value.id) {
          formData.value.share_rate.forEach(item => {
            item.search_id = item.user_id
            item.create_name = item?.user_info.real_name || item?.create_name
            item.create_level = item?.user_info.level.name
            item.share_rate = item?.share_rate
          })
        }
      })
    })

    const isLoading = ref(false)

    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)

    const submitAddProduct = () => {
      isLoading.value = true
      // proxy.$refs.registerForm.validate().then(isOk => {
      //   if (isOk) {
      //     if (error.text || error.component) {
      //       toast.error(error.text)
      //       isLoading.value = false
      //       return
      //     }
      const editData = {
        product_id: '',
        // status: '1',
        number: '',
        deal_time: '',
        // order_tp: '',
        user_id: '',
        share_rate: [],
        protect_user: '', // 被保人
        sign_city: '', // Signed address
        money: '', // FaceAmount
        create_name: '',
        create_id: '',
        create_level: '',
        id: '',
        rate: '',
        except_rate: '',
      }
      Object.keys(editData).forEach(key => {
        if (formData.value[key]) {
          editData[key] = formData.value[key]
        }
      })

      store
        .dispatch('user-order/orderUpdate', {
          ...editData,
        })
        .then(response => {
          if (response.code === 0) {
            toast.success(response.msg || 'Added successfully')
            setTimeout(() => {
              isLoading.value = false
              proxy.$router.back()
            }, 1000)
          } else {
            isLoading.value = false
            toast.error(response.msg)
          }
        })
        .catch(() => {
          toast.error('Failed to add')
          isLoading.value = false
        })
      //   } else {
      //     toast.error('Please complete the form')
      //     isLoading.value = false
      //   }
      // })
    }

    const searchId = ref(null)
    const searchUserId = val => {
      if (!formData.value.share_rate[val].search_id) {
        formData.value.share_rate[val] = {}
        proxy.forceUpdated()
        return
      }
      axiosIns.get(`/user/getUserInfoById?id=${formData.value.share_rate[val].search_id}`).then(res => {
        if (res.code == 0) {
          // 搜索原有数据,查找是否存在该树
          const findIndex = formData.value.share_rate.findIndex(item => item.user_id == res.data.id)
          if (findIndex < 0) {
            nextTick(() => {
              formData.value.share_rate[val].user_id = res.data.id
              formData.value.share_rate[val].share_rate = res.data.share_rate
              formData.value.share_rate[val].create_name = res.data.real_name
              formData.value.share_rate[val].create_level = res.data.level.name
            })
            proxy.forceUpdated()
          } else if (findIndex == 0) {
            toast('Agent Already exists!')
            formData.value.share_rate[val] = {}
            proxy.forceUpdated()
          }
        } else {
          toast(res.msg, {
            type: 'error',
            duration: 2000,
          })
          formData.value.share_rate[val] = {}
          proxy.forceUpdated()
        }
      })
    }
    const comptry = reactive([])
    const cate = reactive([])
    const c = ref('')
    const d = ref('')

    onMounted(() => {
      axiosIns.get('/pro-brand/getBrandList').then(res => {
        if (res.code === 0) {
          comptry.push(...res.data)
        }
      })
      axiosIns.get('/pro-cate/getCateList').then(res => {
        if (res.code === 0) {
          cate.push(...res.data)
        }
      })
    })
    watch(
      [c, d, formData.value.share_rate],
      () => {
        if (c.value && d.value) {
          axiosIns.get(`/product/getProduct?cate_id=${c.value}&brand_id=${d.value}`).then(res => {
            if (res.code === 0) {
              productList.splice(0, productList.length, ...res.data)
              if (res.data.length == 0) {
                formData.value.product_id = ''
              }
              proxy.forceUpdated()
            }
          })
        }
      },
      { immediate: true, deep: true },
    )

    watch(
      () => formData.value.share_rate,
      newVal => {
        let total = 0
        newVal.forEach(item => {
          total += Number(item.share_rate)
        })
        if (total > 100) {
          error.text = 'Split Percent cannot be greater than 100%'
          error.component = 'b-link'
        }
        if (total < 100) {
          error.text = 'Split Percent cannot be less than 100%'
          error.component = 'b-link'
        }
        if (total === 100) {
          error.text = ''
          error.component = ''
        }
      },
      { immediate: true, deep: true },
    )

    const yulandis = ref(true)
    const downLoad = () => {
      document.body.style.cursor = 'wait'
      // console.log(document.querySelector('.woc').offsetWidth)
      setTimeout(() => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()

        html2canvas(document.querySelector('.woc '), {
          height: document.querySelector('.woc').scrollHeight + 300,
          width: document.querySelector('.woc').offsetWidth + 100,
          // height: document.querySelector('.woc').scrollHeight,
          backgroundColor: '#fff',
          scale: 1,
          removeContainer: true,
          allowTaint: true,
          // 背景大小
          bcckgroundSize: 'cover',
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/jpeg')
          doc.addImage(imgData, 'JPEG', 0, 0, 320, 320, '')
          doc.save('invoice.pdf')
          setTimeout(() => {
            document.body.style.cursor = 'default'
          }, 1000)
        })
      }, 1000)
    }

    return {
      yulandis,
      downLoad,
      productList,
      c,
      d,
      comptry,
      cate,
      itemFormBlankItem,
      hasNextPage,
      hasPrevPage,
      formData,
      submitAddProduct,
      isLoading,
      timePickerConfig,
      searchId,
      searchUserId,
      required,
      error,
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   // 进入页面前检查detailInfo是否存在id
  //   next(vm => {
  //     if (!vm.formData?.id) {
  //       vm.$bvToast.toast('Wrong parameter Please select again', {
  //         title: 'Tips',
  //         variant: 'warning',
  //         solid: true,
  //         appendToast: false,
  //         autoHideDelay: 2000,
  //       })
  //       setTimeout(() => {
  //         vm.$router.back()
  //       }, 2000)
  //     } else {
  //       next()
  //     }
  //   })
  // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
 -->
